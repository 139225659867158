import api from '@apiInstance';

export default {
  add(body) {
    return api.post('quotation_headers', body);
  },
  get(id) {
    return api.fetch(`quotation_headers/${id}`);
  },
  async list(params) {
    return await api.fetch('/quotation_headers', { params });
  },
  update(body) {
    return api.patch('quotation_headers', body);
  },
  delete(id) {
    return api.remove('quotation_headers', id);
  },
};
